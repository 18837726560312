import React, { useState, useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import './navigation.scss';

import { useMediaQuery } from 'react-responsive';
import { breakpoints } from './../../../../scripts/breakpoints';

import SolutionsComponent from './SubNavigation/Solutions/Solutions';
import InsightsComponent from './SubNavigation/Insights/Insights';
import AboutComponent from './SubNavigation/About/About';
import { isBrowserDocumentAvailable } from '../../../../utils/HelperUtils';
import { GlobalHeaderNavIDs } from '../../../../constants';
import { NavTopDataAttributes, OtherLinkDataAttributes } from '../../../../constants/gtm';
import BrandsComponent from './SubNavigation/Brands/Brands';
export default function Navigation(props) {
  const {
    scrolled,
    menuItems = [],
    onSubnavOpen,
    onSubnavClose,
    mobileMenuScrollTop,
    handleMobileMenu,
    onSubSubnavOpen,
    componentName = 'SiteHeader',
  } = props;
  const [subNavOpen, setSubNavOpenLocal] = useState(false);
  const [subSubNavOpen, setSubSubNavOpenLocal] = useState(false);

  const [activeMenuItem, setActiveMenuItem] = useState(null);

  const isMobile = useMediaQuery({ maxWidth: '1024px' });

  const toggleSubNavOpen = (menuItemIndex) => {
    if (activeMenuItem === menuItemIndex) {
      setSubNavOpenLocal(false);
      setActiveMenuItem(null);
      onSubnavClose(true);
    } else {
      setSubNavOpenLocal(true);
      setActiveMenuItem(menuItemIndex);
      onSubnavOpen(true);
    }
  };
  const navigationRefs = useRef(menuItems?.map(() => React.createRef()));
  const subNavigationRefs = useRef(menuItems?.map(() => React.createRef()));

  useEffect(() => {
    if (isBrowserDocumentAvailable) {
      const handleDocumentClick = (event) => {
        // Check if the click event occurred outside of a navigation item or its submenu
        const clickedInsideNavigation = navigationRefs?.current?.some(
          (navigationRef) =>
            navigationRef?.current && navigationRef?.current?.contains(event.target)
        );
        const clickedInsideSubNavigation = subNavigationRefs?.current?.some(
          (subNavigationRef) =>
            subNavigationRef?.current && subNavigationRef?.current?.contains(event.target)
        );
        if (!isMobile) {
          if (!clickedInsideNavigation && !clickedInsideSubNavigation) {
            setActiveMenuItem(null);
            onSubnavClose(true);
          }
        }
      };
      document?.addEventListener('click', handleDocumentClick);
      return () => document?.removeEventListener('click', handleDocumentClick);
    }
  }, []);

  const clickedComponent = (item) => {
    let subNavContent;
    const caseToCheck = item?.id || item?.displayName.toLowerCase();
    switch (caseToCheck) {
      case GlobalHeaderNavIDs.Brands:
        subNavContent = (
          <BrandsComponent
            data={item}
            mobileMenuScrollTop={mobileMenuScrollTop}
            onSubSubnavOpen={onSubSubnavOpen}
            handleMobileMenu={handleMobileMenu}
            componentName={componentName}
          />
        );
        break;
      case GlobalHeaderNavIDs.Solutions:
        subNavContent = (
          <SolutionsComponent
            data={item}
            mobileMenuScrollTop={mobileMenuScrollTop}
            onSubSubnavOpen={onSubSubnavOpen}
            handleMobileMenu={handleMobileMenu}
            componentName={componentName}
          />
        );
        break;
      case GlobalHeaderNavIDs.Insights:
        subNavContent = (
          <InsightsComponent
            data={item}
            handleMobileMenu={handleMobileMenu}
            componentName={componentName}
          />
        );
        break;
      case GlobalHeaderNavIDs.AboutUs:
        subNavContent = (
          <AboutComponent
            data={item}
            handleMobileMenu={handleMobileMenu}
            componentName={componentName}
          />
        );
        break;
      default:
        subNavContent = null;
    }
    return subNavContent;
  };

  return isMobile ? (
    <nav className={'mobileNavigation white__background'}>
      <span className="main_menu_scrollto"></span>
      <ul className="mobileNavigation__menu">
        {menuItems &&
          menuItems?.map((item, index) => {
            const isLastSubnav =
              index === menuItems?.length - 1 || !menuItems?.slice(index + 1).some((i) => i);
            const LinksCollections = item?.fields?.LinksCollections;
            const Tabs = item?.fields?.Tabs;
            const ExploreMoreLink = item?.fields?.ExploreMoreLink;
            const { id, title, displayName, url } = item;
            return (
              <React.Fragment key={`key_${title}_${index}_${id}`}>
                <li
                  ref={navigationRefs.current[index]}
                  key={index}
                  className={`mobileNavigation__item ${
                    LinksCollections.length > 0 || Tabs.length > 0 ? 'has_subnav' : ''
                  } ${activeMenuItem === index ? 'active' : ''} ${
                    isLastSubnav ? 'last_of_type' : ''
                  }`}
                >
                  {item ? (
                    <a
                      {...OtherLinkDataAttributes({
                        name: componentName,
                        dataItemName: displayName,
                      })}
                      className={isLastSubnav ? 'language_button' : ''}
                      href={ExploreMoreLink?.value?.href ? ExploreMoreLink?.value?.href : '/'}
                      onClick={(event) => {
                        if (!isLastSubnav) {
                          event.preventDefault();
                          toggleSubNavOpen(index);
                          mobileMenuScrollTop();
                        }
                      }}
                    >
                      {displayName}
                    </a>
                  ) : (
                    <a
                      href={url}
                      onClick={(event) => {
                        event.preventDefault();
                        toggleSubNavOpen(null);
                        handleMobileMenu();
                      }}
                    >
                      {title}
                    </a>
                  )}
                </li>
                {subNavOpen && activeMenuItem === index && item && (
                  <div
                    ref={subNavigationRefs.current[index]}
                    className={`mobileNavigation__submenu-wrapper 
                      ${subNavOpen ? 'open' : ''} 
                      ${subSubNavOpen ? 'sub_open' : ''}
                    `}
                  >
                    <ul className="mobileNavigation__submenu">{clickedComponent(item)}</ul>
                  </div>
                )}
              </React.Fragment>
            );
          })}
      </ul>
    </nav>
  ) : (
    <nav className={`headerNavigation ${scrolled ? 'white__background' : ''}`}>
      <ul className="headerNavigation__menu">
        {menuItems &&
          menuItems?.map((item, index) => {
            const { LinksCollections, Tabs, ExploreMoreLink } = item?.fields;
            const { id, displayName } = item;
            return (
              <React.Fragment key={`key_${displayName}_${index}_${id}`}>
                <li
                  ref={navigationRefs.current[index]}
                  key={index}
                  className={`headerNavigation__item ${
                    LinksCollections?.length > 0 || Tabs?.length > 0 ? 'has_subnav' : ''
                  } ${activeMenuItem === index ? 'active' : ''}`}
                >
                  {LinksCollections?.length > 0 || Tabs?.length > 0 ? (
                    <a
                      href="#"
                      onClick={(event) => {
                        event.preventDefault();
                        toggleSubNavOpen(index);
                      }}
                      {...NavTopDataAttributes({
                        name: componentName,
                        itemLevel: 'L0',
                        dataNavItemName: displayName,
                        dataLinkText: '#',
                        dataLinkImageUrl: '',
                      })}
                    >
                      {displayName}
                    </a>
                  ) : (
                    <a
                      href={ExploreMoreLink?.value?.href}
                      onClick={() => {
                        toggleSubNavOpen(null);
                      }}
                      {...NavTopDataAttributes({
                        name: componentName,
                        itemLevel: 'L0',
                        dataNavItemName: displayName,
                        dataLinkText: ExploreMoreLink?.value?.href || displayName,
                        dataLinkImageUrl: '',
                      })}
                    >
                      {displayName}
                    </a>
                  )}
                </li>

                {navigationRefs.current[index].current &&
                  subNavOpen &&
                  activeMenuItem === index &&
                  displayName && (
                    <div
                      ref={subNavigationRefs.current[index]}
                      className={`headerNavigation__submenu-wrapper ${subNavOpen ? 'open' : ''}`}
                    >
                      <ul className="headerNavigation__submenu">{clickedComponent(item, index)}</ul>
                    </div>
                  )}
              </React.Fragment>
            );
          })}
      </ul>
    </nav>
  );
}
Navigation.defaultProps = {
  mobMenuShow: false,
  scrolled: false,
  menuItems: [],
  onSubnavOpen: () => {},
  onSubnavClose: () => {},
  mobileMenuScrollTop: () => {},
  handleMobileMenu: () => {},
  onSubSubnavOpen: () => {},
  onSubSubnavClose: () => {},
};
Navigation.propTypes = {
  mobMenuShow: PropTypes.bool,
  scrolled: PropTypes.bool,
  menuItems: PropTypes.arrayOf(PropTypes.shape({})),
  onSubnavOpen: PropTypes.func,
  onSubnavClose: PropTypes.func,
  mobileMenuScrollTop: PropTypes.func,
  handleMobileMenu: PropTypes.func,
  onSubSubnavOpen: PropTypes.func,
  onSubSubnavClose: PropTypes.func,
};
