import React from 'react';
import PropTypes from 'prop-types';
import Button from '../../../globals/buttons/Button';
import TextSitecoreField from '../../sitecorefields/TextSitecoreField';
import ImageSitecoreField from '../../sitecorefields/ImageSitecoreField';
import { OtherLinkDataAttributes } from '../../../constants/gtm';
import RichTextSitecoreField from '../../sitecorefields/RichTextSitecoreField';
import { useMediaQuery } from 'react-responsive';
import { breakpoints } from '../../../scripts/breakpoints';
import ButtonHref from '../../../globals/buttons/ButtonHref';
import { richTextCharaterLimit } from '../../../utils/HelperUtils';

const CardServiceGroup = ({
  title,
  subTitle,
  text,
  icon,
  ctaText,
  isActive,
  onShow,
  link,
  linkType,
  linkFields,
  componentName = '',
  dataItemName = '',
}) => {
  const isMobileScreen = useMediaQuery({ maxWidth: breakpoints.md });
  return (
    <>
      {!isMobileScreen ? (
        <a href={link} className={`card service service-group ${isActive ? 'active' : ''}`}>
          <div className="card-body">
            <div className="card-header no-padding">
              {icon && <ImageSitecoreField field={icon} />}
              {isMobileScreen && title?.value && (
                <h3 className="title">
                  <TextSitecoreField field={title} />
                </h3>
              )}
            </div>
            <div className="card-content-service no-padding-bottom">
              {title?.value && (
                <h3 className="title clamp-2">
                  <TextSitecoreField field={title} />
                </h3>
              )}

              {subTitle?.value && (
                <p className="clamp-2">
                  <RichTextSitecoreField field={subTitle} className="sub-title-service" />
                </p>
              )}

              {text?.value && (
                <p>
                  <TextSitecoreField field={richTextCharaterLimit(text, 300)} />
                </p>
              )}
              <ButtonHref
                variant="text-button"
                fontSize="small"
                text={ctaText}
                href={link}
                linkType={linkType}
                linkFields={linkFields}
                cssClass={'black'}
                arrow={false}
                componentName={componentName}
                dataItemName={title?.value}
              />
            </div>
          </div>
        </a>
      ) : (
        <div
          className={`card service service-group ${isActive ? 'active' : ''}`}
          onClick={isMobileScreen ? onShow : undefined}
          // {...accessibleOnClick(onShow)}
        >
          <div className="card-body">
            <div className="card-header">
              {icon && <ImageSitecoreField field={icon} />}
              {isMobileScreen && title?.value && (
                <h3 className="title">
                  <TextSitecoreField field={title} />
                </h3>
              )}
            </div>
            <div className="card-content-service">
              {title?.value && (
                <h3 className="title">
                  <TextSitecoreField field={title} />
                </h3>
              )}
              {subTitle?.value && (
                <p className="sub-title-service">
                  <RichTextSitecoreField field={subTitle} className="sub-title-service" />
                </p>
              )}
              {text?.value && (
                <p>
                  <TextSitecoreField field={text} />
                </p>
              )}
              <ButtonHref
                text={ctaText}
                href={link}
                variant="text-button"
                arrow={false}
                linkType={linkType}
                fontSize="small"
                linkFields={linkFields}
                cssClass={'black'}
                componentName={componentName}
                dataItemName={title?.value}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

CardServiceGroup.defaultProps = {
  title: '',
  text: '',
  icon: '',
  altText: '',
  serviceProvider: '',
  ctaText: '',
  cssClass: '',
  dataItemName: '',
};

CardServiceGroup.propTypes = {
  title: PropTypes.string,
  text: PropTypes.string,
  icon: PropTypes.string,
  altText: PropTypes.string,
  serviceProvider: PropTypes.string,
  ctaText: PropTypes.string,
  cssClass: PropTypes.string,
  dataItemName: PropTypes.string,
};

export default CardServiceGroup;
